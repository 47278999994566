//
// Basic print styles
// --------------------------------------------------
// Source: https://github.com/h5bp/html5-boilerplate/blob/master/css/main.css

@media print {
  @page {
    margin: 0 !important;
  }
  .container {
    padding: 0;
    width: 100%;
  }

  section {
    page-break-before:always;
  }

  form {
    display: none;
  }

  footer {
    display: none;
  }

  html, body {
    height: auto;
    padding-top: 0 !important;
  }
  canvas {
    max-width: 100%;
  }
  .height-wrap {
    padding-bottom:0 !important; /* this needs to be bigger than footer height*/
    overflow: inherit !important;
  }

  .d-print-none {
    display: none;
  }


  // * {
  //   text-shadow: none !important;
  //   color: #000 !important; // Black prints faster: h5bp.com/s
  //   background: transparent !important;
  //   box-shadow: none !important;
  // }

  // a,
  // a:visited {
  //   text-decoration: underline;
  // }

  // a[href]:after {
  //   content: " (" attr(href) ")";
  // }

  // abbr[title]:after {
  //   content: " (" attr(title) ")";
  // }

  // // Don't show links for images, or javascript/internal links
  // .ir a:after,
  // a[href^="javascript:"]:after,
  // a[href^="#"]:after {
  //   content: "";
  // }

  // pre,
  // blockquote {
  //   border: 1px solid #999;
  //   page-break-inside: avoid;
  // }

  thead {
    display: table-header-group; // h5bp.com/t
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 2cm .5cm;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  // Bootstrap components
  .navbar {
    display: none;
  }
  .table {
    td,
    th {
      // background-color: #fff !important;
    }
  }
  .btn,
  .dropup > .btn {
    > .caret {
      // border-top-color: #000 !important;
    }
  }
  .label {
    // border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table-bordered {
    th,
    td {
      // border: 1px solid #ddd !important;
    }
  }

}
