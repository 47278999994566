/*
 * Row with equal height columns
 * -----------------------------
 */
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
  flex: 1;
}

.flex-fill {
  flex-grow: 1;
}

.b2b-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .conversation-area {
    height: 100%;
    .input-group {
      height: 100%;
      textarea {
        height: 100%;
      }
    }
  }

  textarea.foreword {
    background-color: #ffffa5;

  }
}

.mt-4 {
  margin-top: 4em;
}
.mt-2 {
  margin-top: 2em;
}