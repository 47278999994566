.conversation-log-header {
  page-break-inside: avoid;
  b,span {
    display: block;
  }
}

.conversation-log-caller-block {
  margin-top: 1em;
  margin-bottom: 1em;
}

.align-left dt {
  text-align: left;
}