// Place all the styles related to the users/strong_authentication controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/
.strong-auth-qr {
  table {
    border-width: 0;
    border-style: none;
    border-color: #0000ff;
    border-collapse: collapse;
    td {
      border-left: solid 10px #000;
      padding: 0;
      margin: 0;
      width: 0px;
      height: 10px;
      &.black { border-color: #000; }
      &.white { border-color: #fff; }
    }
  }
}