/*!
 * Bootstrap v3.0.0
 *
 * Copyright 2013 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world by @mdo and @fat.
 */

// Core variables and mixins
// VARS.COLORS
@humm-blue: #b8c5cd;
@darker-blue: #3a5260;
@warning-color: #bdbfb5;
@success-color: #c8d9c8;
@info-color: #b8c5cd;

@import "bootstrap/less/variables.less";
@import "custom-bootstrap-variables.less";
@import "bootstrap/less/mixins.less";

// Reset
@import "bootstrap/less/normalize.less";
// import "bootstrap/less/print.less";
@import "bootstrap/less/glyphicons.less";

// Core CSS
@import "bootstrap/less/scaffolding.less";
@import "bootstrap/less/type.less";
@import "bootstrap/less/code.less";
@import "bootstrap/less/grid.less";
@import "bootstrap/less/tables.less";
@import "bootstrap/less/forms.less";
@import "bootstrap/less/buttons.less";

// Components
@import "bootstrap/less/component-animations.less";
@import "bootstrap/less/glyphicons.less";
@import "bootstrap/less/dropdowns.less";
@import "bootstrap/less/button-groups.less";
@import "bootstrap/less/input-groups.less";
@import "bootstrap/less/navs.less";
@import "bootstrap/less/navbar.less";
@import "bootstrap/less/breadcrumbs.less";
@import "bootstrap/less/pagination.less";
@import "bootstrap/less/pager.less";
@import "bootstrap/less/labels.less";
@import "bootstrap/less/badges.less";
@import "bootstrap/less/jumbotron.less";
@import "bootstrap/less/thumbnails.less";
@import "bootstrap/less/alerts.less";
@import "bootstrap/less/progress-bars.less";
@import "bootstrap/less/media.less";
@import "bootstrap/less/list-group.less";
@import "bootstrap/less/panels.less";
@import "bootstrap/less/wells.less";
@import "bootstrap/less/close.less";

// Components w/ JavaScript
@import "bootstrap/less/modals.less";
@import "bootstrap/less/tooltip.less";
@import "bootstrap/less/popovers.less";
@import "bootstrap/less/carousel.less";

// Utility classes
@import "bootstrap/less/utilities.less";
@import "bootstrap/less/responsive-utilities.less";

@import "custom-bootstrap-print.less";

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('fonts/@{icon-font-name}.eot');
  src: url('fonts/@{icon-font-name}.eot?#iefix') format('embedded-opentype'),
  url('fonts/@{icon-font-name}.woff2') format('woff2'),
  url('fonts/@{icon-font-name}.woff') format('woff'),
  url('fonts/@{icon-font-name}.ttf') format('truetype'),
  url('fonts/@{icon-font-name}.svg#@{icon-font-svg-id}') format('svg');
}

@main-gray: #f1f1f1;
@main-font: "Nunito", Sans-Serif;
@dark-font: #242423;
@main-green: #b8c5cd;


//FCA styles
html {
  height: 100%;
}
body {
  height: 100%;
  padding-top: 70px;
}

#report {

  h2, h3 {
    color: @brand-primary;
    font-weight: bold;
    margin: 20px 0;
  }

  .report-content {
    ul {
      margin-top: 2em;
      margin-bottom: 2em;
      li {
        page-break-inside: avoid;
        page-break-before: auto;
        h4 {
          text-transform: uppercase;
        }
        b {
          text-transform: capitalize;
        }
      }
    }
  }

}

.navbar-toggle {
  .fa {
    color: white;
  }
}
.fca-datatable-content {
  white-space: nowrap;
}
.fca-tab-content {
  margin-top: 0.5em;
}
.graph-tool-subcat {
  margin-left: 1em;
}
body.nopad {
  padding: 0 !important;
}
.report-header {
  width: 100%;
  display: block;
  background-color: @brand-primary;
  padding: 25px;
}

.finnchat-logo {
  content: url("images/humm-logo-black.svg");
  width: 156px;
  height: 24px;
  background-position: 0 0;
}
.navbar-brand img {
  height: 20px;
}

.report_extra_info {
  padding: 0;

  td:first-child {
    padding-left: 50px;
  }

  td {
    border-top: none !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.report_additional_info {

  td {
    border-top: none !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

table.table {
  .category-head{
    padding-top: 2em;
    border-top: none;
  }
}

table.tight-table {
  td {
    padding-bottom: 4px !important;
  }
}

.multiselect-container {
  label.checkbox {
    margin-top: 0px;
    margin-bottom: 0px;
    min-height: 9px;
    input {
      margin-left: -15px;
    }
  }
}

.divider-vertical {
  margin-left: 9px;
  margin-right: 9px;
  border-left: 2px solid #F2F2F2;
  border-right: 1px solid #FFF;
  a {
    padding-left: 10px;
  }
}

#users {
  option.review-group-office {
    font-weight: bold;
  }
  option.review-group-team {
    padding-left: 50px;
  }

}

.col-centered {
  float:none;
  margin: 0 auto;
}
.little-margin {
  margin-top: 0.5em;
}
.tag-list {
  li {
    margin-left: 1em;
  }
}

.checkbox input[type="checkbox"] {
  margin-left: 0;
}

.checkbox label input[type="checkbox"] {
  margin-left: -20px;
}

.report_content {
  padding:0.5em;
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}



.ctrl-panel-links {
  list-style: none;
  li {
    padding: 0.5em;
  }

  li:before {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0 10px;
    content: "\f054";
  }
}

pre {
  white-space: pre-wrap;       /* CSS 3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
  word-break: normal;
}

.metric-selectors {
  .input-group-btn {
    display: inline;
  }
}
.footer {
  position: relative;
  margin-top: -170px; /* negative value of footer height */
  height: 150px;
  clear:both;
  padding-top:20px;
}

.height-wrap {
  min-height: 100%;
  overflow:auto;
  padding-bottom:170px; /* this needs to be bigger than footer height*/

}

.auto-report-success {
  font-size: 18px;
  color: @brand-primary;

}
.auto-report-failure {
  font-size: 18px;
  color: @brand-danger;

}

#fca-client-goals-list {
  .auto-report-success, .auto-report-failure {
    margin-right: 1em;
  }
}

.offline-contact {
  b {
    display: inline-block;
    width: 100px;
    margin-right: 1em;
  }
}

.lead-group-name {
  margin-top: 1em;
}

.wr-lead-group-name {
  margin-top: 2em;
}

.mb-2 {
  margin-bottom: 2em;
}
.mb-4 {
  margin-bottom: 4em;
}

.login-logo {
  background-color: @humm-blue;
  img {
    max-width: inherit;
    margin: 5em 0;
    padding: 0 1em;
  }
}

@media(max-width: @screen-md) {
  .login-logo {
    img {
      // max-width: 100%;
    }
  }
}

// LOGIN
.cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background: no-repeat center;
  background-size: cover;
  z-index: -1;
  background-image: url("images/bg-login-2500x1914.jpg");

  &:after {
    content: '';
    background: @humm-blue;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    opacity: 0.9;
    height: 100%;
    width: 100%;
  }
}
.loginPage {
  padding: 30px;
  background: #FFF;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.15);

  .logo {
    display: block;
    width: 100%;
    max-width: 300px;
    margin: 0 auto 20px;
  }

  h1 {
    text-align: center;
    border-top: 1px dashed @darker-blue;
    padding-top: 20px;
    font-size: 21px;
  }
  p {
    text-align: center;
  }

  .btn-success {
    background: @humm-blue;
    border-color: @darker-blue;
    text-shadow: 0 1px 2px rgba(0,0,0,0.5);

    &:hover {
      background: @darker-blue;
    }
  }

}


.transcript-display {
  .transcript-header {
    float: left;
    .data-entry {
      vertical-align: top;
      display: inline-block;
      font-size: 14px;
      margin-right: 0.5em;
      color: @main-green;
      height: 45px;
      span {
        display: block;
        font-weight: 700;
      }
      .value {
        font-weight: normal;
      }
      &.start-url .value {
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .message {
    span {
      font-weight: 700;
    }
  }
}

@import "conversation-log.less"; //OK
@import "popover.less"; // ok
@import "trafficlights.less"; //ok
@import "strong_authentication";
@import "b2b_fancybox";

@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

#chat-status-app {
  font-family: @main-font;
  color: @dark-font;
}

.chat-service {
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  height: 50px;
  color: #eee;
  .chat-service-inner {
    padding-left: 0.75em;
    padding-right: 0.75em;
  }
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  line-height:50px;
  overflow: hidden;
  .client-name {
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    max-width: 250px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .statuses {
    font-size: 24px;
    padding-right: 1em;
    i {
      margin-right: 0.5em;
    }
  }
  .timestamp {
    width: 130px;
    text-align: right;
  }
}

.serviceview {
  .last-check-ts {
    display: inline-block;
    margin-bottom: 1.5em;
  }
  .status-icon {
    position: absolute;
    top: 50px;
    right: 50px;
    font-size: 64px;

  }
  .status-icon-good {
    color: @main-green;
  }
  .status-icon-bad {
    color: #b94a48;
  }
}

.chat-status-screenshot {
  cursor: pointer;
  .test-url {
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
    width: 90%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  img {
    max-width: 100%;
  }
  .outer-dialog {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
    position: fixed;
    .dialog {
      .close-button {
        position: absolute;
        right: 1px;
        top: -2px;
        font-size: 32px;
      }
      .chat-status-link {
        margin-top: 0.25em;
        font-size: 16px;
        font-weight: 700;
      }
      z-index: 999;
      position: fixed;
      width: 50%;
      left: 25%;
      top: 5%;
      border: 1px solid #333;
      border-radius: 5px;
      background-color: white;
      text-align: center;
      padding-top: 1em;
      padding-bottom: 1em;
    }
  }
}

.status-good {
  background-color: @main-green;

}

.status-bad {
  background-color: #b94a48;
}

.chat-closed {
  background-color: rgba(255,255,255, 0.5);
}

@media (max-width: @screen-xs-max) {
  .chat-status-screenshot .outer-dialog .dialog {
    left: 5%;
    top: 20%;
    width: 90%;
  }
}
@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  .chat-status-screenshot .outer-dialog .dialog {
    left: 10%;
    width: 80%;
  }
}
@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
  .chat-status-screenshot .outer-dialog .dialog {
    left: 15%;
    width: 70%;
  }

}
@media (min-width: @screen-lg-min) {

}

#chat-push-save {
  min-height: 500px;
  position: relative;
  p, .radio {
    margin-right: 50px;
  }
  .client{
    position: absolute;
    left:0;
    top: 0;
    bottom: 0;
    width: 20%;
    padding: 0;
    overflow-y: auto;
  }
  .conversation {
    left:20%;
    top: 0;
    bottom: 0;
    position: absolute;
    width: 30%;
    padding: 0;
    overflow-y: auto;
  }
  .record {
    left:50%;
    top: 0;
    bottom: 0;
    position: relative;
    width: 50%;
  }
  .list-group-item {
    border-radius: 0 !important;
    .mug {
      display: none;
    }
    p {
      height: 25px;
    }
  }
  .spacer-chat,
  .spacer-client{
    background-color: lighten(#3CA58E,40%);
    .mug {
      background-image: url('images/tauottaja-icon.png');
      background-size: 100%;
      display:inline-block;
      width: 24px;
      height: 22px;
      margin-right: 6px;
    }
    &.active {
      background-color: #3CA58E;
    }
  }

  .caller-status-init {
    background-color: #00b3ee;

  }
  .caller-status-sales-ringing {
    background-color: #faebcc;

  }
  .caller-status-sales-answered {
    background-color: #ffff00;

  }
  .caller-status-customer-ringing {
    background-color: #2d9587;

  }
  .caller-status-customer-answered {
    background-color: #0bb264;
  }
}

@media(max-width: @screen-xs-max) {
  #chat-push-save {
    .selections {
      max-height: 300px;
    }
    .client {
      position: relative;
      left: 0;
      width: 50%;
      float: left;
    }
    .conversation {
      position: relative;
      left: 0;
      width: 50%;
      float: left;
    }
    .record {
      position: relative;
      width: 100%;
      left: 0;
      clear: both;
    }
  }

}
