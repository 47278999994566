#gauges {
  div {
    svg {
      float: none;
      margin: 0 auto;
    }
  }
}

@trafficlight-width: 60px;
@trafficlight-height: 60px;
#traffic-light-box {
  margin-top: 25px;
  padding: 5px;
  background-color: darkslategray;
  border-radius: 49%;
  width: @trafficlight-width + 10px;
  height: @trafficlight-height + 10px;
  margin-left: auto;
  margin-right: auto;
}
#trafficlight {
  width: @trafficlight-width;
  height: @trafficlight-height;
}

.red{
  background: #c9302c;
  background-image: radial-gradient(brown, transparent);
  background-size: 5px 5px;
  width: @trafficlight-width;
  height: @trafficlight-height;
  border-radius: 50%;
  box-shadow:
          0 0 20px #111 inset,
          0 0 10px red;
}

.yellow{
  background: yellow;
  background-image: radial-gradient(orange, transparent);
  background-size: 5px 5px;
  width: @trafficlight-width;
  height: @trafficlight-height;
  border-radius: 50%;
  box-shadow:
          0 0 20px #111 inset,
          0 0 10px yellow;
}

.green{
  background: #2d9587;
  background-image: radial-gradient(lime, transparent);
  background-size: 5px 5px;
  width: @trafficlight-width;
  height: @trafficlight-height;
  border-radius: 50%;
  box-shadow:
          0 0 20px #111 inset,
          0 0 10px lime;
}
.go-up {
  -ms-transform: translate(0px,-200px); /* IE 9 */
  -webkit-transform: translate(0px,-200px); /* Chrome, Safari, Opera */
  transform: translate(0px,-200px);
  font-size: 128px;
  color: green;
  transition: color 2s, font-size 2s, transform 2s, opacity 4s;
  opacity: 0;
}

.arrow-container {
  position: absolute;
  left:0px;
  right:0px;
  bottom:0px;
}
.tooltip-inner {
  max-width: 250px;
}
